import { CloseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import * as dayjs from 'dayjs';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { CrudFilters, useApiUrl, useCustomMutation } from '@refinedev/core';
import {
  FilterDropdown,
  getDefaultSortOrder,
  List,
  ShowButton,
  TextField,
} from '@refinedev/antd';
import { Button, DatePicker, Popconfirm, Select, Space, Table } from 'antd';
import { ShortId } from './ShortId';
import { IUsersModel } from '../models/UsersModel';
import { DateTimeField } from './DateTimeField';
import useTableWithSearch from '../hooks/useTableWithSearch';
import { ColumnsType } from 'antd/es/table';
import TableTitleWithCount from './TableTitleWithCount';

const states = ['creating', 'waiting', 'error', 'complete'];
const type = ['import'];

type UserListProps = {
  teamId?: string;
  listProps?: object;
};

const FileOperationsList: React.FC<UserListProps> = ({ teamId, listProps }) => {
  const [period, setPeriod] = useState<Date[]>([]);
  const [state, setState] = useState<string[]>(['waiting']);
  const [format, setFormat] = useState('');
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const apiUrl = useApiUrl();
  const { mutate: start } = useCustomMutation({
    mutationOptions: {
      retry: false,
    },
  });

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IUsersModel[]) => {
      setSelectedRows(selectedRows.map(row => row.id));
    },
  };

  const startOperation = useCallback((ids: string[], mode: 'start' | 'stop') => {
    start({
      url: `${ apiUrl }/file_operations/operation`,
      method: 'post',
      values: {
        ids,
        mode,
      },
      successNotification: (data) => {
        return {
          message: 'FileOperations started',
          type: 'success',
        };
      },
      errorNotification: (data) => {
        return {
          message: 'FileOperations fail!',
          type: 'error',
        };
      },
    });
  }, [apiUrl, start])

  const permanentFilters = useMemo(() => {
    const filter: CrudFilters = [];
    if (teamId) {
      filter.push({
        field: 'teamId',
        operator: 'eq',
        value: teamId,
      })
    }

    if (state) {
      filter.push({
        field: 'state',
        operator: 'in',
        value: state,
      })
    }

    // filter.push({
    //   field: 'createdAt',
    //   operator: 'between',
    //   value: period.map(d => d
    //     .toISOString()
    //     .replace('T', ' ')
    //     .split('.')[0]
    //   ),
    // })

    return filter;
  }, [state, teamId]);

  const {
    tableData: { tableProps, sorter, tableQueryResult },
    getColumn,
    clear,
  } = useTableWithSearch<IUsersModel>({
    resource: 'file_operations',
    permanentFilters,
    filterFields: [
      'state',
      'type',
      'size',
      'error',
      'format',
      'createdAt',
      'updatedAt',
    ],
    initialSorter: [
      {
        field: 'updatedAt',
        order: 'desc',
      },
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
  });

  const columns = useMemo<ColumnsType<IUsersModel>>(() => {
    return [
      {
        key: 'id',
        dataIndex: 'id',
        title: 'ID',
        render: ShortId,
        filterDropdown: (props) => (
          <FilterDropdown { ...props }>
            <Select
              mode="tags"
              style={ { width: '100%', minWidth: '200px' } }
              placeholder="Select ID"
            />
          </FilterDropdown>
        ),
      },
      {
        ...getColumn('state'),
        dataIndex: 'state',
        key: 'state',
        title: 'State',
        defaultSortOrder: getDefaultSortOrder('state', sorter),
        sorter: true,
      },
      {
        dataIndex: 'type',
        key: 'type',
        title: 'Type',
        defaultSortOrder: getDefaultSortOrder('type', sorter),
        sorter: true,
        ...getColumn('type'),
      },
      {
        dataIndex: ['team', 'name'],
        key: 'team.name',
        title: 'Team',
        render: (value, record: IUsersModel) => (
          <ShowButton
            size="small"
            resource="teams"
            recordItemId={ record.teamId }
          >
            <TextField value={ value } />
          </ShowButton>
        ),
        defaultSortOrder: getDefaultSortOrder('team.name', sorter),
        sorter: true,
      },
      {
        dataIndex: 'size',
        key: 'size',
        title: 'size',
        render: (value) => Math.round(value / 1024 / 1024) + 'Mb',
        defaultSortOrder: getDefaultSortOrder('size', sorter),
        sorter: true,
      },
      {
        dataIndex: 'createdAt',
        key: 'createdAt',
        title: 'createdAt',
        render: DateTimeField,
        defaultSortOrder: getDefaultSortOrder('createdAt', sorter),
        sorter: true,
      },
      {
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        title: 'updatedAt',
        render: DateTimeField,
        defaultSortOrder: getDefaultSortOrder('updatedAt', sorter),
        sorter: true,
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        render: (_, record) => (
          <Space>
            <Button
              icon={ <PlayCircleOutlined /> }
              onClick={ () => startOperation([record.id], 'start') }
            />
            <Popconfirm
              title="Отменить операцию?"
              description="Будет изменен статус операции, если она в процессе, процесс продолжится"
              onConfirm={ () => startOperation([record.id], 'stop') }
              okText="Yes"
              cancelText="No"
            >
              <Button
                danger
                icon={ <CloseCircleOutlined /> }
              />
            </Popconfirm>
          </Space>
        ),
      },
    ] as ColumnsType<IUsersModel>;
  }, [getColumn, sorter, startOperation]);

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Space.Compact>
          <DatePicker.RangePicker
            value={ [dayjs(period[0]), dayjs(period[1])] }
            showTime
            allowClear={ true }
            onChange={ (values) => {
              setPeriod([values[0].toDate(), values[1].toDate()]);
            } }
          />
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
            value={state}
            onChange={setState}
            options={states.map(s => ({ label: s, value: s }))}
          />
        </Space.Compact>
        <Space.Compact>
          {
            selectedRows.length ? (
              <Button
                icon={ <PlayCircleOutlined /> }
                onClick={ () => startOperation(selectedRows, 'start') }
              />
            ) : null
          }
        </Space.Compact>
      </div>
      <p></p>
      <List
        title={
          <TableTitleWithCount
            title="FileOperations"
            tableQueryResult={ tableQueryResult }
          />
        }
        headerButtons={ ({ defaultButtons }) => (
          <>
            <Button onClick={ clear }>Clear filters</Button>
            { defaultButtons }
          </>
        ) }
        { ...listProps }
      >
        <Table
          { ...tableProps }
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={ columns }
          size="small"
          rowKey="id"
        />
      </List>
    </>
  );
};

export default FileOperationsList;
