import * as dayjs from "dayjs";
import * as advancedFormat from "dayjs/plugin/advancedFormat";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
import * as localeData from "dayjs/plugin/localeData";
import * as weekday from "dayjs/plugin/weekday";
import * as weekOfYear from "dayjs/plugin/weekOfYear";
import * as weekYear from "dayjs/plugin/weekYear";
import * as relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/ru";

dayjs.locale('ru');
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(relativeTime);
