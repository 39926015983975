import { IResourceComponentsProps } from "@refinedev/core";
import React from "react";
import FileOperationsList from '../../../components/FileOperationsList';
import ImportStatus from '../../../components/ImportStatus';

export const FileOperations: React.FC<IResourceComponentsProps> = () => {
  return (
    <>
      <ImportStatus />
      <br />
      <FileOperationsList
        listProps={{ canCreate: false }}
      />
    </>
  )
};
