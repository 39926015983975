import { ReloadOutlined, SaveOutlined } from '@ant-design/icons';
import { useUpdate } from '@refinedev/core';
import { Button, Popconfirm, Table } from 'antd';
import React, { useCallback, useState } from 'react';
import ReactJson, { InteractionProps } from 'react-json-view';
import { ITeamModel } from '../../models/TeamModel';

type Change = {
  key: string
  oldVal: string
  newVal: string
}

export type EditTeamProps = {
  team: ITeamModel
  onChange?: (team: ITeamModel) => void
}

const EditTeam: React.FC<EditTeamProps> = ({
  team,
}) => {
  const [localTeam, setLocalTeam] = useState<ITeamModel>(team);
  const [changes, setChanges] = useState<Change[]>([]);
  const { mutate: updateTeam, isLoading: isUpdating } = useUpdate();

  const saveTeam = useCallback(() => {
    updateTeam({
      resource: 'teams',
      id: team.id,
      values: localTeam,
    }, {
      onSuccess: () => setChanges([])
    });
  }, [localTeam, team.id, updateTeam])

  const onJsonChange = useCallback((data: InteractionProps) => {
    const ch: Change[] = [
      ...changes,
      { key: data.name, newVal: data.new_value, oldVal: data.existing_value } as Change,
    ];
    setChanges(ch);
    setLocalTeam(data.updated_src as ITeamModel);
  }, [changes])

  const columns = [
    {
      key: 'key',
      dataIndex: 'key',
      title: 'Поле',
    },
    {
      key: 'oldVal',
      dataIndex: 'oldVal',
      title: 'Старое значение',
    },
    {
      key: 'newVal',
      dataIndex: 'newVal',
      title: 'Новое значение',
    },
  ]

  return (
    <>
      <ReactJson
        src={ localTeam }
        onEdit={ onJsonChange }
      />
      <br />
      <Table<Change>
        columns={ columns }
        dataSource={ changes }
        pagination={ false }
      />
      <br />
      <div style={ { textAlign: 'right' } }>
        <Button
          icon={ <ReloadOutlined /> }
          disabled={!changes.length || isUpdating}
          style={{ marginRight: '10px' }}
          onClick={() => { setChanges([]); setLocalTeam(team) }}
        >
          Сбросить
        </Button>
        <Popconfirm
          title="Внимание!"
          description="Точно сохранить?"
          onConfirm={saveTeam}
          okText="Да, точно"
          cancelText="Отмена"
        >
        <Button
          type="primary"
          icon={ <SaveOutlined /> }
          loading={isUpdating}
          disabled={!changes.length}
        >
          Сохранить
        </Button>
        </Popconfirm>
      </div>
      <br />
    </>
  )
}

export default EditTeam
