import React, { FC, useEffect, useState } from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, Edit, useForm } from "@refinedev/antd";
import {
  AutoComplete,
  Col,
  Form,
  FormProps,
  Input,
  Row,
  Select,
  Switch,
} from "antd";
import { IPaymentMethodModel } from "../models/PaymentMethodModel";
import { SelectItem } from "../../../components/SelectItem";
import UsersModel from "../../../models/UsersModel";

export const PaymentMethodForm: React.FC<{
  formProps: FormProps<{}>;
  onFinish: (values: unknown) => Promise<unknown>;
}> = ({ formProps, onFinish }) => {
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const paymentGateway = Form.useWatch("paymentGateway", formProps.form);
  const teamId = Form.useWatch("teamId", formProps.form);
  const orgType = Form.useWatch("card.type", formProps.form);

  useEffect(() => {
    const card = formProps.form.getFieldValue("card");
    if (card) {
      Object.keys(card).forEach((key) => {
        formProps.form.setFieldValue("card." + key, card[key]);
      });
      formProps.form.setFieldValue("card", null);
    }
  });

  useEffect(() => {
    if (
      paymentGateway === "BankAccount" &&
      !formProps.form.getFieldValue("type")
    ) {
      formProps.form.setFieldValue("type", "bank_account");
    }
  }, [formProps.form, paymentGateway]);

  const handleOnFinish = (values) => {
    const card = {};
    for (let key of Object.keys(values)) {
      if (key.startsWith("card.")) {
        const parts = key.split(".");
        card[parts[1]] = values[key];
        delete values[key];
      }
    }

    if (Object.keys(card).length) {
      values["card"] = card;
    }

    onFinish(values);
  };

  return (
    <Form
      {...formProps}
      onFinish={onFinish ? handleOnFinish : formProps.onFinish}
      layout="vertical"
    >
      {formProps.form.getFieldValue("id") && (
        <Form.Item label="ID" name="id">
          <Input readOnly disabled />
        </Form.Item>
      )}
      <Row gutter={10}>
        <Col span={10}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            label="Payment Gateway"
            name="paymentGateway"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              options={[
                { label: "BankAccount", value: "BankAccount" },
                { label: "YooKassa", value: "YooKassa" },
                { label: "Tinkoff", value: "Tinkoff" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            label="Type"
            name="type"
            rules={[
              {
                required: true,
              },
            ]}
            style={{ minWidth: "120px" }}
          >
            <AutoComplete
              options={[
                {
                  label: "bank_card",
                  value: "bank_card",
                },
                {
                  label: "bank_account",
                  value: "bank_account",
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Is active" name="isActive" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={5}>
          <Form.Item
            label="Team"
            name="teamId"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <SelectItem
              resource="teams"
              labelKey="subdomain"
              placeholder="Select team"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            label="User"
            name="userId"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <SelectItem<string, typeof UsersModel>
              resource="users"
              labelKey="email"
              placeholder="Select user"
              disabled={!formProps.form.getFieldValue("teamId")}
              onSelect={(val, option) => setSelectedUserEmail(option.email)}
              onChange={(val, option) => setSelectedUserEmail(option.email)}
              filters={[
                {
                  field: "teamId",
                  operator: "eq",
                  value: teamId,
                },
                {
                  field: "deletedAt",
                  operator: "null",
                  value: null,
                },
                {
                  field: "suspendedAt",
                  operator: "null",
                  value: null,
                },
                {
                  field: "isViewer",
                  operator: "eq",
                  value: false,
                },
              ]}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            label="Payment Method Id"
            tooltip={
              "ИД платежного шлюза. Для Юкассы это ИД карты для списания"
            }
            name="paymentMethodId"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <AutoComplete
              options={[
                {
                  label: (
                    <>
                      <div>Расчетный счет Тинькоф</div>
                      <strong>40702810210001339029</strong>
                    </>
                  ),
                  value: "40702810210001339029",
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      {paymentGateway === "BankAccount" && (
        <Row gutter={10}>
          <Col>
            <Form.Item
              label="Тип организации"
              name="card.type"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={[
                  { label: "ООО", value: "ООО" },
                  { label: "ИП", value: "ИП" },
                  { label: "ПАО", value: "ПАО" },
                  { label: "НКО", value: "НКО" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Название организации"
              name="card.name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="ИНН"
              name="card.inn"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          {orgType !== "ИП" && (
            <Col>
              <Form.Item label="КПП" name="card.kpp">
                <Input />
              </Form.Item>
            </Col>
          )}
          <Col>
            <Form.Item
              label={orgType === "ИП" ? "ОГРНИП" : "ОГРН"}
              name="card.ogrn"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Адрес"
              name="card.address"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Email для отправки документов"
              name="card.email"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <AutoComplete
                options={[
                  {
                    label: selectedUserEmail,
                    value: selectedUserEmail,
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item label="ЭДО" name="card.edo">
              <Select
                style={{ width: "100%" }}
                options={[
                  { label: "Нет ЭДО", value: "" },
                  { label: "Sbis", value: "sbis" },
                  { label: "Kontur", value: "kontur" },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export const PaymentMethodCreate: React.FC<IResourceComponentsProps> = (
  props
) => {
  const { formProps, saveButtonProps, onFinish } =
    useForm<IPaymentMethodModel>();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("teamId")) {
      formProps.form.setFieldValue("teamId", params.get("teamId"));
    }
  }, [formProps.form]);

  return (
    <Create saveButtonProps={saveButtonProps}>
      <PaymentMethodForm formProps={formProps} onFinish={onFinish} />
    </Create>
  );
};

export const PaymentMethodEdit: FC<IResourceComponentsProps> = (props) => {
  const { formProps, saveButtonProps, onFinish } =
    useForm<IPaymentMethodModel>();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <PaymentMethodForm formProps={formProps} onFinish={onFinish} />
    </Edit>
  );
};
