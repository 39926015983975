import { useApiUrl, useCustom } from '@refinedev/core';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import * as dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { ShortId } from './ShortId';

export type ImportState = {
  id: string
  state: "init" | "downloading" | "parsing" | "importing" | "error" | "success"
  error?: string
  collections: number
  collectionsProcessed: number
  documents: number
  documentsProcessed: number
  databases: number
  databasesProcessed: number
  rows: number
  rowsProcessed: number
  attachments: number
  attachmentsProcessed: number
  lastUpdate: number
}

const ImportStatus = () => {
  const [imports, setImports] = useState<ImportState[]>([]);

  const apiUrl = useApiUrl();
  const { refetch, isFetching, data } = useCustom({
    url: `${apiUrl}/file_operations/import_status`,
    method: 'get'
  });

  useEffect(() => {
    if (!data || !data.data) {
      return;
    }

    let imports = [];
    Object.keys(data.data).forEach(key => {
      const dt: ImportState = { id: key, ...data.data[key] };
      imports.push(dt);
    });
    imports = imports.sort((a, b) => {
      if (a.lastUpdate > b.lastUpdate) return -1;
      if (a.lastUpdate < b.lastUpdate) return 1;
      return 0;
    })
    setImports(imports);
  }, [data]);

  useEffect(() => {
    const timer = setInterval(() => refetch(), 5000);
    return () => {clearInterval(timer)}
  }, [refetch]);

  const columns: ColumnsType<ImportState> = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
      render: ShortId,
    },
    {
      dataIndex: 'state',
      key: 'state',
      title: 'state',
      sorter: true,
    },
    {
      dataIndex: 'documents',
      key: 'documents',
      title: 'Documents',
      sorter: true,
    },
    {
      dataIndex: 'documentsProcessed',
      key: 'documentsProcessed',
      title: 'Processed',
      sorter: true,
    },
    {
      dataIndex: 'attachments',
      key: 'attachments',
      title: 'Attachments',
      sorter: true,
    },
    {
      dataIndex: 'attachmentsProcessed',
      key: 'attachmentsProcessed',
      title: 'Processed',
      sorter: true,
    },
    {
      dataIndex: 'lastUpdate',
      key: 'lastUpdate',
      title: 'LastUpdate',
      sorter: true,
      render: (val) => dayjs(val).fromNow(),
    },
  ]

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={imports}
    />
  )
}

export default ImportStatus;
